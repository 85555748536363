import React, { Fragment, memo } from "react"
import { graphql } from "gatsby"
import * as sections from "../containers/Homepage/components/sections"
import DropdownFixedNavbar from "../components/Navbars/DropdownFixedNavbar"
import FooterBlack from "../components/Footers/FooterBlack/index"
import HomepageContainer from "../containers/Homepage"
import SEO from "../components/SEO"
import { Helmet } from "react-helmet"

interface Props {
  data: {
    homepage: {
      id: string
      title: string
      image: { id: string; url: string }
      blocks: sections.HomepageBlock[]
    }
  }
}

const Homepage = memo((props: Props) => {
  const { homepage } = props.data

  return (
    <Fragment>
      <Helmet
        title="Leighton Electrical"
        description="Leighton Electrical | New Zealand"
        meta={[
          {
            property: `og:title`,
            content: "Leighton Electrical",
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: "description",
            content: "Leighton Electrical | New Zealand",
          },
          {
            property: `og:image`,
            // You should ideally replace the hardcoded URL below with a value you set
            // in your gatsby-config.js file.  And import all shared site metadata into
            // this component with the useStaticQuery hook.
            content: `https://leighton.gtsb.io/static/LeightonElectricalWhite.png`,
          },
        ]}
      />
      <SEO />
      <DropdownFixedNavbar />
      <HomepageContainer blocks={homepage?.blocks} />
      <FooterBlack />
    </Fragment>
  )
})

export default Homepage

export const query = graphql`
  {
    homepage {
      id
      title
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HomepageHeroContent
        ...HomepageProductListContent
        ...HomepageTestimonialListContent
      }
    }
  }
`
