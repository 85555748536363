import clsx from "clsx"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { memo } from "react"
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap"
import { HomepageImage, Container } from "../../../../components/ui"
import * as classes from "./styles.module.scss"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"

interface Testimonial {
  id: string
  avatar: HomepageImage
  content: string
  name: string
  role: string
}

export interface TestimonialProps {
  id: string
  background: HomepageImage
  title: string
  description: string
  testimonialList: Testimonial[]
}

const HomepageTestimonialList = memo((props: TestimonialProps) => {
  return (
    <div className={clsx("testimonials-1 pt-3", classes.background)}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <h2 className={clsx("title", classes.colorWhite, classes.title)}>
              {props.title}
            </h2>
            <h4
              className={clsx(
                "description text-white mb-0",
                classes.description
              )}
            >
              {props.description}
            </h4>
          </Col>
        </Row>
        <Row>
          {props.testimonialList.map((item) => (
            <Col md="4" key={item.id} className="pt-5">
              <Card
                className={clsx("card-testimonial", classes.cardTestimonial)}
              >
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <GatsbyImage
                      className={clsx(
                        "img img-raised",
                        classes.testimonialAvatar
                      )}
                      alt={item.avatar.alt}
                      image={getImage(item.avatar.gatsbyImageData)}
                    />
                  </a>
                </div>
                <CardBody className={classes.cardBodyTestimonial}>
                  <p className="card-description mb-0">{item.content}</p>
                </CardBody>
                <div className="icon icon-info mb-3">
                  <FormatQuoteIcon className={classes.itemIcon} />
                </div>
                <CardFooter className="mb-0">
                  <CardTitle tag="h4" className="mt-0">
                    {item.name}
                  </CardTitle>
                  <p className="category">{item.role}</p>
                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
})

export default HomepageTestimonialList

export const query = graphql`
  fragment HomepageTestimonialListContent on HomepageTestimonialList {
    id
    background {
      alt
      id
      gatsbyImageData
    }
    title
    description
    testimonialList: content {
      id
      avatar {
        alt
        id
        gatsbyImageData
      }
      content
      name
      role
    }
  }
`
