import clsx from "clsx"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React, { memo } from "react"
import { Card, Col, Row } from "reactstrap"
import { Container, HomepageImage } from "../../../../components/ui"
import * as classes from "./styles.module.scss"

interface Product {
  id: string
  title: string
  description: string
  productImage: HomepageImage
}

export interface ProductProps {
  id: string
  background: HomepageImage
  title: string
  description: string
  productList: Product[]
}

const iconList = [
  "now-ui-icons shopping_shop",
  "now-ui-icons business_bulb-63",
  "now-ui-icons business_money-coins",
  "now-ui-icons ui-2_settings-90",

]

const linkList = [
  "/sub-services/#section2",
  "/main-services/#section4",
  "/main-services/#section3",
  "/sub-services/#subServiceSection5",

]

const HomepageProductList = memo((props: ProductProps) => {
  return (
    <div className={clsx("section section-images", classes.sectionImages)}>
      <BackgroundImage
        className="features-8 section-image pt-4"
        {...convertToBgImage(getImage(props.background.gatsbyImageData))}
      >
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2 className={clsx("title", classes.title)}>{props?.title}</h2>
          <h4 className={clsx("description mb-0", classes.description)}>
            {props?.description}
          </h4>
        </Col>
        <Container>
          <Row>
            {props.productList.map((item, index) => (
              <Col md="3" key={index} className="mt-5">
                <Card className={classes.card}>
                  <div className="card-image">
                    <GatsbyImage
                      className={clsx(classes.imgItem, "img img-raised")}
                      alt={item.productImage.alt}
                      image={getImage(item.productImage.gatsbyImageData)}
                    />
                  </div>
                  <div className="info text-center pb-0">
                    <div className="icon">
                      <i className={iconList[index % iconList.length]}></i>
                    </div>
                    <h4 className="info-title">
                      <Link to={linkList[index]}>{item?.title}</Link>
                    </h4>
                    <p className="description">{item?.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </BackgroundImage>
    </div>
  )
})

export default HomepageProductList

export const query = graphql`
  fragment HomepageProductListContent on HomepageProductList {
    id
    background {
      alt
      id
      gatsbyImageData
    }
    title
    description
    productList: content {
      id
      title
      description
      productImage {
        alt
        id
        gatsbyImageData
      }
    }
  }
`
