import React, { memo, useState } from "react"
import {
  Col,
  Row,
  Card,
  Form,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import { compose, withProps } from "recompose"
import * as classes from "./styles.module.scss"
import clsx from "clsx"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import { graphql, useStaticQuery } from "gatsby"
import "../../../../styles/gravity.css"

interface Props {}

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `900px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => (
  <GoogleMap
    defaultZoom={18}
    defaultCenter={{ lat: -35.22818, lng: 173.94912 }}
  >
    <Marker
      // label="Biz Space"
      position={{ lat: -35.22818, lng: 173.94912 }}
      onClick={(props) => console.log(props)}
    />
  </GoogleMap>
))

const ContactUs = memo((props: Props) => {
  const data = useStaticQuery(graphql`
    query formQueryHomepage {
      wp {
        ...GravityFormSettings
      }
      wpGfForm(databaseId: { eq: 1 }) {
        ...GravityFormFields
      }
    }
  `)

  const [first2Focus, setFirst2Focus] = useState(false)
  const [email2Focus, setEmail2Focus] = useState(false)

  return (
    <div
      className={clsx(classes.section, "section section-basic-components pt-0")}
      id="contact-us"
    >
      <div className="big-map" id="contactUsMap">
        <MyMapComponent />
        <Col className={classes.formWrapper}>
          <Card
            className={clsx(classes.cardWrapper, "card-contact card-raised")}
          >
            <div>
              <CardHeader className="text-center">
                <CardTitle tag="h4">Contact Us</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6">
                    <div className="ml-0 info info-horizontal p-0">
                      <div className="icon icon-info mt-0">
                        <i
                          className={clsx(
                            classes.textWhite,
                            "now-ui-icons tech_mobile"
                          )}
                        ></i>
                      </div>
                      <div className="description">
                        <h5
                          className={clsx(
                            classes.textWhite,
                            "info-title mt-0 mb-1"
                          )}
                        >
                          Give us a ring
                        </h5>
                        <p className={classes.textWhite}>09 404 0044</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="ml-0 info info-horizontal p-0">
                      <div className="icon icon-info mt-0">
                        <i
                          className={clsx(
                            classes.textWhite,
                            "now-ui-icons ui-1_email-85"
                          )}
                        ></i>
                      </div>
                      <div className="description">
                        <h5
                          className={clsx(
                            classes.textWhite,
                            "info-title mt-0 mb-1"
                          )}
                        >
                          Email
                        </h5>
                        <p className={classes.textWhite}>
                          info@leightonelectrical.nz
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="12">
                    <div className="ml-0 info info-horizontal p-0">
                      <div className="icon icon-info mt-0">
                        <i
                          className={clsx(
                            classes.textWhite,
                            "now-ui-icons location_pin"
                          )}
                        ></i>
                      </div>
                      <div className="description">
                        <h5
                          className={clsx(
                            classes.textWhite,
                            "info-title mt-0 mb-1"
                          )}
                        >
                          Location
                        </h5>
                        <p className={classes.textWhite}>
                          6/32 Klinac lane, Waipapa
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <GravityFormForm data={data} />
              </CardBody>
            </div>
          </Card>
        </Col>
      </div>
    </div>
  )
})

export default ContactUs
