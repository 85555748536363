import React, { memo } from "react"
import * as sections from "./components/sections"
import Fallback from "../../components/fallback"
import ContactUs from "./components/ContactUs"

interface Props {
  blocks: sections.HomepageBlock[]
}

const HomepageContainer = memo(({ blocks }: Props) => {
  return (
    <div className="wrapper">
      {blocks?.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...(componentProps as any)} />
      })}
      <ContactUs />
    </div>
  )
})

export default HomepageContainer
