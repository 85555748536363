import { graphql } from "gatsby"
import React, { memo } from "react"
import * as classes from "./styles.module.scss"
import { Button, Col, Container, Row } from "reactstrap"
import clsx from "clsx"
import { HomepageImage } from "../../../../components/ui"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { Facebook, Instagram } from "react-feather"

export interface HeroProps {
  id: string
  background: HomepageImage
  title: string
  description1: string
  description2: string
  buttonText: string
  twitterUrl: string
  facebookUrl: string
  readMoreUrl: string
}

const HomepageHero = memo((props: HeroProps) => {
  return (
    <div className={clsx("page-header clear-filter", classes.pageHeader)}>
      <BackgroundImage
        className={clsx(classes.pageHeaderImage, "page-header-image")}
        {...convertToBgImage(getImage(props?.background?.gatsbyImageData))}
      >
        <Container>
          <Row className={classes.contentWrapper}>
            <Col className="text-left" md="12">
              <Container className={classes.xyz}>
              <h1 className={clsx("title", classes.title)}>{props.title}</h1>
              <h4 className={clsx("description", classes.description)}>
                {props.description1}
              </h4>
              <h4 className={clsx("description", classes.description)}>
                {props.description2}
              </h4>
              <br></br>
              <div className={clsx(classes.btnWrapper, "buttons")}>
                <Button
                  className={clsx(
                    classes.btnIconWrapper,
                    "btn-icon btn-neutral"
                  )}
                  color="link"
                  href={`//${props.facebookUrl}`}
                  target="_blank"
                  size="md"
                >
                  <Facebook className={classes.btnIcon} />
                </Button>
                <Button
                  className={clsx(
                    classes.btnIconWrapper,
                    "btn-icon btn-neutral"
                  )}
                  color="link"
                  href={`//${props.readMoreUrl}`}
                  target="_blank"
                  size="md"
                >
                  <Instagram className={classes.btnIcon} />
                </Button>
                <Button color="#888888" href="/enquiry" size="md">
                  {props.buttonText}
                </Button>
              </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </div>
  )
})

export default HomepageHero

export const query = graphql`
  fragment HomepageHeroContent on HomepageHero {
    id
    background {
      alt
      id
      gatsbyImageData
    }
    title
    description1
    description2
    buttonText
    twitterUrl
    facebookUrl
    readMoreUrl
  }
`
